import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ClientDataLinkButton = (props) => {
  const { idClient, ...buttonProps } = props;

  if (!idClient) {
    return <Button type="ghost">Error</Button>;
  }

  return (
    <Link to={`/admin/client/${idClient}/datos`}>
      <Button {...buttonProps} />
    </Link>
  );
};
ClientDataLinkButton.propTypes = {
  label: PropTypes.string, // Etiqueta del botón (opcional)
  icon: PropTypes.node, // Icono del botón (opcional)
  idClient: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonProps: PropTypes.object, // Propiedades adicionales para el componente Button (opcional)
};

export default ClientDataLinkButton;
