import React from 'react';

import { DocumentosProvider } from 'providers/Administradores/DocumentosProvider';
import { ActionsPresupuestosProvider } from 'providers/Administradores/ActionsPresupuestoProvider';
import { ClientesProvider } from 'providers/Clientes/ClientesProvider';
import { ActionsComprobantesProvider } from 'providers/ActionsComprobantesProvider';
import { ActionsMantencionesDepProvider } from 'providers/ActionsMantencionDepProvider';
import { ActionsODTProvider } from 'providers/ActionsOdtProvider';

const whitAdministradorProvider = (Component) => {
  const WrappedComponent = (props) => (
    <DocumentosProvider>
      <ActionsPresupuestosProvider>
        <ActionsComprobantesProvider>
          <ActionsMantencionesDepProvider>
            <ActionsODTProvider>
              <ClientesProvider>
                <Component {...props} />
              </ClientesProvider>
            </ActionsODTProvider>
          </ActionsMantencionesDepProvider>
        </ActionsComprobantesProvider>
      </ActionsPresupuestosProvider>
    </DocumentosProvider>
  );

  WrappedComponent.displayName = `WhitAdministradorProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default whitAdministradorProvider;
