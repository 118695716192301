import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import React from 'react';
import BudgetSmallLogo from '../BudgetSmallLogo/BudgetSmallLogo';
import { API_PATH } from 'config';

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    margin: '10px 10px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header_textContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    marginRight: '20px',
    textTransform: 'uppercase',
  },
  headerTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '15px',
  },
  logo: {
    width: '4.76cm',
    height: '3.34cm',
    // position: 'absolute',
    right: '0px',
    // bottom: '-1.7cm',
    backgroundColor: 'white',
    // border: '1px solid black',
    marginRight: '1cm',
    padding: '3px',
  },
});

function BudgetHeader({ config }) {
  return (
    <View fixed style={styles.header}>
      <View>
        <Image
          style={styles.logo}
          source={
            config?.portraitLogo
              ? `${API_PATH}${config?.portraitLogo}`
              : 'LIFT LOGIC'
          }
        />
        {/* <BudgetSmallLogo /> */}
      </View>
      <View style={styles.header_textContainer}>
        <Text style={styles.headerTitle}>
          {config?.titulo_segundario ?? 'Lift Logic'}
        </Text>
      </View>
      <View style={styles.header_textContainer}>
        {config?.titulo_derecha?.map((data) => (
          <Text style={styles.textItem} key={data}>
            {data}
          </Text>
        ))}
      </View>
    </View>
  );
}

export default BudgetHeader;
