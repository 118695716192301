/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid black',
    fontFamily: 'Times-Bold',
  },
  cell: {
    borderRight: '1px solid black',
    height: '100%',
  },
});

function TableHeader(props) {
  const { columns } = props;
  return (
    <View style={styles.row}>
      {columns.map((column) => (
        <Text key={column.key} style={{ width: column.width, ...styles.cell }}>
          {column.title}
        </Text>
      ))}
    </View>
  );
}

export default TableHeader;
