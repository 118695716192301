import { Document, StyleSheet, View } from '@react-pdf/renderer';
import moment from 'moment';
import { arrayOf, shape } from 'prop-types';
import React from 'react';
import PageA4 from '../Elements/Pages/PageA4';
import Table from '../Elements/Table/Table';
import P from '../Elements/Text/P';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import OrderData from './OrderData';
import H1 from 'PDF/Elements/Text/H1';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';

const styles = StyleSheet.create({
  page: {
    paddingBottom: '150px',
    paddingTop: '140px',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    marginBottom: '120px',
    left: 0,
  },
  body: {
    marginHorizontal: '1.27cm',
  },
  titleContainer: {
    fontSize: 16,
    fontFamily: 'Times-Bold',
    color: 'white',
    backgroundColor: '#17365D',
    width: '270px',
    paddingVertical: '10px',
    marginLeft: '15px',
    textAlign: 'center',
    marginTop: '70',
  },
  dataContainer: {
    marginTop: 15,
  },
  signaturesContainer: {
    marginTop: 15,
  },
  header: {
    marginHorizontal: '1.27cm',
    width: '100vw',
    position: 'absolute',
  },
});
const columns = [
  {
    width: '3%',
    title: 'N°',
    key: 'number',
  },
  {
    width: '17%',
    title: 'Ascensores',
    key: 'elevator',
  },
  {
    width: '80%',
    title: 'Descripción',
    key: 'description',
  },

  // {
  //   width: '25%',
  //   title: 'Finaliza',
  //   key: 'date_finish',
  // },
];
function OrderDocument(props) {
  const { data, config } = props;
  let { items } = props;
  items = items.map((item, index) => ({
    ...item,
    date_finish: moment(item.date_finish).format('YYYY-MM-DD HH:MM'),
    number: index,
  }));
  const {
    specialist_signature: specialistSignature,
    recepcionist_signature: recepcionistSignature,
    specialist_name: specialistName,
    recepcionist_name: recepcionistName,
    recepcionist_charge: recepcionistCharge,
    specialist_cargo: specialistCargo,
    specialist_especialidad: specialistEspecialidad,
    specialist_otros: specialistOtros,
  } = data;

  return (
    <Document>
      <PageA4
        style={{
          paddingTop: '4.09cm',
        }}
      >
        <View
          style={{
            position: 'absolute',
          }}
          fixed
        >
          <Header config={config} title={`ODT N°${data.id_budget}`} />
        </View>
        <H1>Datos Cliente</H1>
        <View style={styles.dataContainer}>
          <OrderData data={data} />
        </View>
        <H1>Items</H1>
        <P>
          En la siguiente tabla, se observan los trabajos realizados, los
          ascensores involucrados y la fecha de finalización de cada item
        </P>
        <Table columns={columns} data={items} />
        <View wrap={false}>
          <H1>Información de Entrega</H1>
          <P>
            A continuación, se encuentra la firma y nombre tanto del
            especialista como de quien recibe los trabajos. Esto, cuenta como
            una constancia firmada de que se finalizó la orden de trabajo.
          </P>
          <RecSpeSignatures
            specialistSignature={specialistSignature}
            recepcionistName={recepcionistName}
            recepcionistSignature={recepcionistSignature}
            recepcionistCharge={recepcionistCharge}
            specialistName={specialistName}
            specialistEspecialidad={specialistEspecialidad}
            specialistCargo={specialistCargo}
            specialistOtros={specialistOtros}
          />
        </View>
        <View
          fixed
          style={{
            position: 'absolute',
            height: '80px',
            width: '100vw',
            bottom: '0',
          }}
        >
          <Footer config={config} />
        </View>
      </PageA4>
    </Document>
  );
}
OrderDocument.propTypes = {
  data: shape({}).isRequired,
  items: arrayOf(shape({})).isRequired,
};

export default OrderDocument;
