import { SOLICITUD_TIPOS } from 'assets/data/SolicitudesEstados';
import { useAppApi, useUAppApi } from 'hooks/useAppApi';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCalendarActions } from './CalendarSolc/CalendarActionsProvider';

const SolicitudesContext = createContext(null);

export const SolicitudesProvider = ({ children, id }) => {
  const { selectedDay } = useCalendarActions();
  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/solicitudes',
    mapResults: map,
  });

  const mapUpdate = useCallback((result) => {
    console.log(result);
    return result;
  }, []);

  const { call: update, isLoading: isLoadingUpdate } = useUAppApi({
    url: '/solicitudes',
    mapResults: mapUpdate,
  });

  const saveSolicitud = async (solicitud) => {
    const { SolicitudTipo, Cliente, ...solc } = solicitud;
    await update(solc, `/${solicitud.id}`);
    call();
  };
  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    console.log('data cambio');
    setSolicitudes(data);
  }, [data]);

  const addSolicitudToDay = async (solicitud, day) => {
    const newSolicitud = {
      ...solicitud,
      fecha_asignacion: day.format('YYYY-MM-DD'),
      status: solicitud.encargado
        ? SOLICITUD_TIPOS.ASIGNADA
        : SOLICITUD_TIPOS.SIN_ASIGNAR,
      // status: ASIGNADO,
      // status: ASIGNADO,
    };
    await saveSolicitud(newSolicitud);
  };

  const deleteSolicitudToDay = async (solicitud, day, index) => {
    const newSolicitud = {
      ...solicitud,
      fecha_asignacion: null,
      status: SOLICITUD_TIPOS.SIN_ASIGNAR,
    };
    await saveSolicitud(newSolicitud);
  };

  const asignSpecialist = async (solicitud, rut) => {
    const newSolicitud = {
      ...solicitud,
      encargado: rut,
      status: SOLICITUD_TIPOS.ASIGNADA,
    };

    await saveSolicitud(newSolicitud);
  };

  return (
    <SolicitudesContext.Provider
      value={{
        call,
        id,
        isLoading,
        error,
        hasCalled,
        data: solicitudes,
        addSolicitudToDay,
        deleteSolicitudToDay,
        asignSpecialist,

        selectedData: solicitudes?.filter(
          (solicitud) =>
            solicitud.fecha_asignacion === selectedDay.format('YYYY-MM-DD')
        ),
        // activeData: data?.filter((element) => element.active === true),
      }}
    >
      {children}
    </SolicitudesContext.Provider>
  );
};

export const useSolicitudes = () => useContext(SolicitudesContext);
