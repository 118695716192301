// const { default: LayoutMain } = require("components/layouts/LayoutMain");
// const { default: Inicio } = require("pages/administrador/inicio");

import LayoutMain from 'components/layouts/LayoutMain';
import Inicio from 'pages/administrador/inicio';

const administradorRoutes = [
  {
    path: '/',
    layout: LayoutMain,
    element: Inicio,
  },
];
export default administradorRoutes;
