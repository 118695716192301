import { useCallback, useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import localforage from 'localforage';
import { API_PATH } from 'config';
import { getAccessTokenApi } from 'api/auth';

// Configurar axios-retry
axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error) =>
    error.response.status >= 500 || error.code === 'ECONNABORTED',
});

const useAppApi = ({ baseUrl, mapResults }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [hasCalled, setHasCalled] = useState(false);
  const token = getAccessTokenApi();

  const call = useCallback(
    async ({ queryParams, extUrl = '' } = {}) => {
      setHasCalled(true);
      setIsLoading(true);
      const query = queryParams
        ? new URLSearchParams(queryParams).toString()
        : '';
      const url = `${API_PATH}${baseUrl}${extUrl}${query ? `?${query}` : ''}`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: token,
          },
        });
        setData(mapResults(response.data));
        return response.data;
      } catch (err) {
        setError(err);
        return err;
      } finally {
        setIsLoading(false);
      }
    },
    [baseUrl, mapResults, token]
  );

  return { data, isLoading, error, hasCalled, call };
};

const usePAppApi = ({ url, mapResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [hasCalled, setHasCalled] = useState(false);
  const token = getAccessTokenApi();

  const call = useCallback(
    async (body) => {
      setHasCalled(true);
      setIsLoading(true);
      const key = `postRequest_${url}`;
      await localforage.setItem(key, { body });

      const fullUrl = `${API_PATH}${url}`;
      try {
        const response = await axios.post(fullUrl, body, {
          headers: {
            Authorization: token,
          },
        });
        await localforage.removeItem(key);
        return mapResults(response.data);
      } catch (err) {
        setError(err);
        return Promise.reject(err);
      } finally {
        setIsLoading(false);
      }
    },
    [url, mapResults, token]
  );

  return { isLoading, error, hasCalled, call };
};

const useUAppApi = ({ url, mapResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [hasCalled, setHasCalled] = useState(false);
  const token = getAccessTokenApi();

  const call = useCallback(
    async (body, extUrl = '', queryParams) => {
      setHasCalled(true);
      setIsLoading(true);
      const query = queryParams
        ? new URLSearchParams(queryParams).toString()
        : '';
      const _url = `${API_PATH}${url}${extUrl}${query ? `?${query}` : ''}`;
      const key = `putRequest_${url}`;
      await localforage.setItem(key, { body, extUrl, queryParams });

      try {
        const response = await axios.put(_url, body, {
          headers: {
            Authorization: token,
          },
        });
        await localforage.removeItem(key);
        return mapResults(response.data);
      } catch (err) {
        setError(err);
        return Promise.reject(err);
      } finally {
        setIsLoading(false);
      }
    },
    [url, mapResults, token]
  );

  return { isLoading, error, hasCalled, call };
};

export { useAppApi, usePAppApi, useUAppApi };
