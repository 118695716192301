// # Estados Solicitud Ejecutiva

import { css } from 'antd-style';
import {
  ATRASADO,
  COMPLETADO,
  CREADA,
  INICIADA,
  INICIADO,
  VIGENTE,
} from 'assets/data/SolicitudesEstados';

// - CREADA: Para cuando una solicitud se crea pero no se ha asignado ni hecho nada.
// - - Gris
// - VIGENTE: Para cuando la solicitud se ha asignado y no se ha completado.
// - - Azul
// - ATRASADO: Para cuando la solicitud se ha asignado y se ha atrasado.
// - - Rojo
// - COMPLETADO: Para cuando la solicitud se ha completado y se ha asignado.
// - - Verde
// - INICIADA: Para cuando la solicitud se ha iniciado y no se ha completado.
// - - Amarillo

export const SOLICITUDES_MANTENCION_STATUS = [
  VIGENTE,
  CREADA,
  ATRASADO,
  COMPLETADO,
  INICIADA,
];

const solicitudesMantencionStatus = (status) => {
  switch (status) {
    case 'CREADA':
      return {
        color: 'gray',
        text: 'CREADA',
        mantLabel: 'En proceso',
        css_class: 'gray-400',
      };
    case 'VIGENTE':
      return {
        color: 'blue',
        text: 'VIGENTE',
        mantLabel: 'Iniciar Mantención',
        css_class: 'blue-200',
      };
    case 'ATRASADO':
      return {
        color: 'red',
        text: 'ATRASADO',
        mantLabel: 'Ir a Mantención',
        css_class: 'red-600',
      };
    case 'COMPLETADO':
      return {
        color: 'green',
        text: 'COMPLETADO',
        mantLabel: 'Revisar',
        css_class: 'green-500',
      };
    case 'INICIADA':
      return {
        color: 'yellow',
        text: 'INICIADA',
        mantLabel: 'Ir a Mantención',
        css_class: 'yellow-600',
      };
    default:
      return {
        color: 'gray',
        text: 'CREADA',
        mantLabel: 'En proceso',
        css_class: 'gray-400',
      };
  }
};
export default solicitudesMantencionStatus;
