import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const ClienteContext = createContext(null);

export const ClienteProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    console.log(result);
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call: callNotFunctional,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });
  const call = async (id_client) => {
    const result = await callNotFunctional(undefined, `/${id_client}`);
    console.log(result);
    return result;
  };

  const {
    data: dataConfig,
    isLoading: loadingConfig,
    error: errorConfig,
    hasCalled: hasCalledConfig,
    call: callNotFunctionalConfig,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });
  const callConfig = async (id_client) => {
    const result = await callNotFunctionalConfig({
      extUrl: `/${id_client}/config`,
    });
    return result;
  };

  const { call: updateConfig } = useUAppApi({
    url: '/clientes',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });

  const saveConfig = async (id_client, data) => {
    const result = await updateConfig(data, `/${id_client}/config`);
    return result;
  };

  return (
    <ClienteContext.Provider
      value={{
        call,
        id,
        loading,
        error,
        hasCalled,
        data,
        callConfig,
        dataConfig,
        loadingConfig,
        errorConfig,
        hasCalledConfig,
        saveConfig,
      }}
    >
      {children}
    </ClienteContext.Provider>
  );
};

export const useCliente = () => useContext(ClienteContext);
