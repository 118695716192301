import { BASE_PATH_SERVER } from './config';

export const getBudgetsApi = async (token) => {
  const dst = `${BASE_PATH_SERVER}get-budget`;
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const postBudgetApi = async (token, data) => {
  const dst = `${BASE_PATH_SERVER}add-budget`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const postBudgetImageApi = async (token, image, budgetId) => {
  const dst = `${BASE_PATH_SERVER}upload-budget-image/${budgetId}`;

  const formData = new FormData();
  formData.append('image', image.file, image.name);

  const params = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const getItemsApi = async (token, id) => {
  const dst = `${BASE_PATH_SERVER}get-items-budget/${id}`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getBudgetConfigApi = async (token) => {
  const dst = `${BASE_PATH_SERVER}presupuestos/config`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getOrderConfigApi = async (token) => {
  const dst = `${BASE_PATH_SERVER}presupuestos/ordenes/config`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getImagesApi = async (token, id) => {
  const dst = `${BASE_PATH_SERVER}get-images-budget/${id}`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getImageApi = async (imgName) => {
  const dst = `${BASE_PATH_SERVER}get-image-budget/${imgName}`;
  const params = {
    method: 'GET',
  };
  return fetch(dst, params)
    .then((response) => response.url)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const editBudgetApi = async (token, data, idBudget) => {
  const dst = `${BASE_PATH_SERVER}edit-budget/${idBudget}`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const getBudgetStatements = async () => {
  const dst = `${BASE_PATH_SERVER}get-budget-statements`;
  return fetch(dst)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const toggleActivatedBudgetApi = async (token, activated, id) => {
  const dst = `${BASE_PATH_SERVER}toggle-activate-budget`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      activated,
      id,
    }),
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
