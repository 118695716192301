import { Spin } from 'antd';
import { number } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useApi } from '../../hooks/useApi';
import Dowload from './Dowload';
import { getAccessTokenApi } from 'api/auth';
import { getOrderConfigApi } from 'api/budget';

function PDFOrderContainer(props) {
  const { idBudget } = props;
  const [orderData, refresh] = useApi({
    url: `order/document-data?idBudget=${idBudget}`,
    defaultValue: {
      order: undefined,
      items: undefined,
    },
    successEnabled: false,
    key: 'order',
  });

  const [config, setConfig] = useState({});
  const token = getAccessTokenApi();
  useEffect(() => {
    getOrderConfigApi(token).then((result) => {
      setConfig(result);
    });
  }, [token]);

  useEffect(() => {
    if (orderData.order || orderData.items) {
      refresh();
    }
  }, [refresh, orderData]);
  const { order, items } = orderData;

  return (
    <div>
      {order && items ? (
        <Dowload config={config} items={items} data={order} />
      ) : (
        <Spin />
      )}
    </div>
  );
}

PDFOrderContainer.propTypes = {
  idBudget: number.isRequired,
};

export default PDFOrderContainer;
