import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
// import styles from './styles';
const styles = StyleSheet.create({
  logoSM: {
    width: '100%',
  },
  proSM: {
    fontSize: '10px',
    fontWeight: 'heavy',
    fontFamily: 'Helvetica-Bold',
    color: '#7D7D7C',
    marginLeft: '5px',
  },
  liftSM: {
    marginTop: '-5px',
    textTransform: 'uppercase',
    fontSize: '50px',
    fontWeight: 'heavy',
    fontFamily: 'Helvetica-Bold',
  },
  ingenieriaSM: {
    marginTop: '-10px',
    fontSize: '14px',
    fontWeight: 'heavy',
    fontFamily: 'Helvetica-Bold',
    marginLeft: '40px',
    color: '#2A2D8C',
  },
});

function BudgetSmallLogo() {
  return (
    <View style={styles.logoSM}>
      <Text style={styles.proSM}>Pro</Text>
      <Text style={styles.liftSM}>LIFT</Text>
      <Text style={styles.ingenieriaSM}>Ingeniería</Text>
    </View>
  );
}

export default BudgetSmallLogo;
