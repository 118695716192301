import { Select, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
const options = [
  {
    value: 'presupuestos',
    label: 'Presupuestos',
  },
  // {
  //   value: 'preventivos',
  //   label: 'Mantenciones Preventivas',
  // },
  {
    value: 'mantencionesDep',
    label: 'Mantenciones Preventivas (hasta 2024)',
  },
  {
    value: 'comprobantes',
    label: 'Comprobantes de Atención',
  },
  // {
  //   value: 'odt',
  //   label: 'Orden de Trabajo',
  // },
];

const DocSelect = ({ onChange, defaultValue }) => {
  return (
    <Space direction="vertical" className="w-full">
      <Paragraph className="font-bold">Tipo de Documento:</Paragraph>
      <Select
        className="w-full"
        placeholder="Seleccione"
        defaultValue={defaultValue}
        options={options}
        onChange={(value) => onChange(value)}
      />
    </Space>
  );
};

export default DocSelect;
