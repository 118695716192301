import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { rutValidator } from '../../utils/formValidator';

function UserForm(props) {
  const { submitForm, buttonLabel, formProps, editing = false } = props;

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [formProps, form]);

  const finishForm = async () => {
    const values = form.getFieldsValue();
    const response = await submitForm(values, formProps);
    if (response.resetFields) {
      form.resetFields();
    }
  };

  return (
    <Form {...formProps} form={form} onFinish={finishForm} layout="vertical">
      <Form.Item
        name="fullname"
        label="Nombre Completo"
        rules={[
          {
            required: true,
            message: 'El nombre completo es obligatorio',
          },
          {
            max: 180,
            min: 0,
            message: 'Este campo no puede tener más de 180 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre Completo" />
      </Form.Item>
      <Form.Item
        name="rut"
        label="Rut"
        rules={[
          {
            max: 12,
            min: 0,
            message: 'Este campo no puede tener más de 12 caracteres',
          },
          {
            required: true,
            message: 'El rut es obligatorio',
          },
          {
            validator: (_, value) =>
              rutValidator(value)
                ? Promise.resolve()
                : Promise.reject(new Error('El rut ingresado no es válido')),
          },
        ]}
      >
        <Input placeholder="Rut" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: 'Debe Ingresar un correo electrónico válido',
          },
          {
            max: 80,
            min: 0,
            message: 'Este campo no puede tener más de 80 caracteres',
          },
        ]}
      >
        <Input placeholder="Correo Electrónico" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Tipo de usuario"
        rules={[
          {
            required: true,
            message: 'El tipo de usuario es obligatorio',
          },
        ]}
      >
        <Select placeholder="Tipo de usuario">
          <Select.Option value="ejecutivo">Ejecutivo Administrador</Select.Option>
          <Select.Option value="especialista">Especialista</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="password"
        label="Contraseña"
        rules={[
          { required: !editing, message: 'La contraseña es obligatoria' },
          {
            max: 40,
            min: 0,
            message: 'Este campo no puede tener más de 40 caracteres',
          },
        ]}
      >
        <Input.Password placeholder="Contraseña" />
      </Form.Item>
      <Form.Item
        name="repeatPassword"
        label="Repita la contraseña"
        rules={[
          {
            required: !editing,
            message: 'La repetición de la contraseña es obligatoria',
          },
          {
            max: 40,
            min: 0,
            message: 'Este campo no puede tener más de 40 caracteres',
          },
          {
            validator: (_, value) =>
              form.getFieldValue('password') !== value
                ? Promise.reject(
                    new Error('Las contraseñas deben ser exactamente iguales')
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <Input.Password placeholder="Repita su contraseña" />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {buttonLabel || 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UserForm;
