import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
  },
  separation: {
    margin: '0px 20px',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
});

function BudgetDataTransfer({ config = {} }) {
  const { datos_transferencia } = config;
  return (
    <View wrap={false}>
      <Text style={styles.title}>5- Datos de transferencia:</Text>
      <Text style={styles.leyend}>{datos_transferencia?.banco}</Text>
      <Text style={styles.leyend}>{datos_transferencia?.tipo_cuenta}</Text>
      <Text style={styles.leyend}>N° {datos_transferencia?.numero}</Text>
      <Text style={styles.leyend}>{datos_transferencia?.nombre}</Text>
      <Text style={styles.leyend}>{datos_transferencia?.rut}</Text>
      <Text style={styles.leyend}>{datos_transferencia?.correo}</Text>
      <View style={styles.separation} />
      <Text style={styles.leyend}>{datos_transferencia?.extra}</Text>
    </View>
  );
}

export default BudgetDataTransfer;
