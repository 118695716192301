import { DownloadOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { coloresEstados } from 'assets/data/estadosPresupuesto';
import moment from 'moment';
import React from 'react';

// eslint-disable-next-line react/display-name
const whitDoc = (Component) => (props) => {
  const { type, data, customActions, ...tableProps } = props;
  const columns = getColumns(type, customActions);
  const dataSource = getData(data, type);

  return (
    <Component
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      {...tableProps}
    />
  );
};

function getData(data, type) {
  switch (type) {
    case 'preventivos':
      return [];
    case 'mantencionesDep':
      return data?.mantencionesDep || [];
    case 'odt':
      return [];
    case 'presupuestos':
      return data?.presupuestos || [];
    case 'comprobantes':
      return data?.comprobantes || [];
    default:
      return [];
  }
}

function getColumns(type, customActions) {
  switch (type) {
    case 'preventivos':
      return [
        {
          title: 'Nombre',
          dataIndex: 'nombre',
        },
        {
          title: 'Fecha',
          dataIndex: 'fecha',
        },
        {
          title: 'Descripción',
          dataIndex: 'descripcion',
        },
      ];
    case 'mantencionesDep':
      return [
        {
          title: 'N°',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Edificio',
          dataIndex: 'Cliente',
          render: (Cliente) => <span>{Cliente?.name}</span>,
        },
        {
          title: 'Fecha',
          dataIndex: 'insert_in',
          render: (fecha) => <span>{moment(fecha).format('YYYY/MM/DD')}</span>,
        },
        {
          title: 'Hora',
          dataIndex: 'insert_in',
          render: (fecha) => <span>{moment(fecha).format('h:mm a')}</span>,
        },
        {
          title: 'Nombre Recepcionista',
          dataIndex: 'recepcionist_name',
          render: (value) => <span className="uppercase">{value}</span>,
        },
        {
          title: 'Acciones',
          key: 'action',
          render: customActions[type],
        },
      ];
    case 'comprobantes':
      return [
        {
          title: 'N°',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Edificio',
          dataIndex: 'Cliente',
          render: (Cliente) => <span>{Cliente?.name}</span>,
        },
        {
          title: 'Fecha',
          dataIndex: 'date',
          render: (fecha) => <span>{moment(fecha).format('YYYY/MM/DD')}</span>,
        },
        {
          title: 'Hora',
          dataIndex: 'date',
          render: (fecha) => <span>{moment(fecha).format('h:mm a')}</span>,
        },
        {
          title: 'Razón',
          dataIndex: 'reason',
          render: (value) => <span className="uppercase">{value}</span>,
        },
        {
          title: 'Acciones',
          key: 'action',
          render: customActions[type],
        },
      ];
    case 'odt':
      return [
        {
          title: 'Número de orden',
          dataIndex: 'numeroOrden',
        },
        {
          title: 'Cliente',
          dataIndex: 'cliente',
        },
        {
          title: 'Fecha',
          dataIndex: 'fecha',
        },

        {
          title: 'Estado',
          dataIndex: 'estado',
        },
      ];
    case 'presupuestos':
      return [
        {
          title: 'N°',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Edificio',
          dataIndex: 'Cliente',
          render: (Cliente) => <span>{Cliente?.name}</span>,
        },

        {
          title: 'Razón',
          dataIndex: 'reason',
        },
        {
          title: 'Fecha',
          dataIndex: 'createdOn',
          render: (fecha) => <span>{moment(fecha).format('YYYY/MM/DD')}</span>,
        },
        {
          title: 'Hora',
          dataIndex: 'createdOn',
          render: (fecha) => <span>{moment(fecha).format('h:mm a')}</span>,
        },

        {
          title: 'Estado',
          dataIndex: 'status',
          render: (status) => (
            <Tag color={coloresEstados[status]?.color ?? 'gray'}>
              {coloresEstados[status]?.description ?? 'SIN DEFINIR'}
            </Tag>
          ),
          filters: [
            {
              value: 'odt_no_asignada',
              text: 'Generada',
            },
            {
              value: 'odt_asignada',
              text: 'Orden de Trabajo',
            },
            {
              value: 'odt_en_proceso',
              text: 'Orden Iniciada',
            },
            {
              value: 'odt_finalizada',
              text: 'Orden Finalizada',
            },
          ],
          onFilter: (value, record) => {
            return record.status.indexOf(value) === 0;
          },
        },
        {
          title: 'Acciones',
          key: 'action',
          render: customActions[type],
        },
      ];
    default:
      return [];
  }
}

export default whitDoc;
