import { usePDF } from '@react-pdf/renderer';
import React from 'react';
import BudgetDocument from '../BudgetDocument/BudgetDocument';
import BudgetDowloadProp from './propTypes';

function BudgetDowload(props) {
  const { budget } = props;
  const [instance] = usePDF({ document: <BudgetDocument budget={budget} /> });
  if (instance.loading) return <div>Cargando...</div>;
  if (instance.error) return <div>Algo salió mal: {instance.error}</div>;
  return (
    <a href={instance.url} download={`NDR-${budget.idBudget}.pdf`}>
      Descargar
    </a>
  );
}
BudgetDowload.propTypes = BudgetDowloadProp;

export default BudgetDowload;
