/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from '../styles';
import BudgetPortrait from '../BudgetPortrait/BudgetPortrait';
import BudgetHeader from '../BudgetHeader/BudgetHeader';
import BudgetFooter from '../BudgetFooter/BudgetFooter';
import BudgetBody from '../BudgetBody/BudgetBody';
import { getAccessTokenApi } from '../../../api/auth';
import {
  getBudgetConfigApi,
  getImagesApi,
  getItemsApi,
} from '../../../api/budget';

function BudgetDocument(props) {
  const { budget } = props;
  const { idBudget: id, reason, name } = budget;
  const token = getAccessTokenApi();
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [config, setConfig] = useState({});
  useEffect(() => {
    getItemsApi(token, id).then((result) => {
      setItems(result.items);
    });
  }, [token, id]);
  useEffect(() => {
    getBudgetConfigApi(token).then((result) => {
      setConfig(result);
    });
  }, [token, id]);
  useEffect(() => {
    getImagesApi(token, id).then((result) => {
      setImages(result.images);
    });
  }, [token, id]);

  return (
    <Document>
      <Page size="A4">
        <BudgetPortrait id={id} reason={reason} name={name} config={config} />
      </Page>

      <Page size="A4" wrap style={styles.page}>
        <BudgetHeader config={config} />
        <BudgetBody
          budget={budget}
          items={items}
          images={images}
          config={config}
        />
        <BudgetFooter config={config} />
      </Page>
    </Document>
  );
}
const budgetProps = {
  idBudget: PropTypes.number.isRequired,
  reason: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
BudgetDocument.propTypes = {
  budget: PropTypes.shape(budgetProps).isRequired,
};

export default BudgetDocument;
