import React from 'react';
import { ClientesProvider as ClientesProviderDep } from 'providers/ClientesProvider';
import { ClienteAutoProvider } from 'providers/ClienteAutomatizadoProvider';
import { MantencionProvider } from 'providers/MantencionProvider';
import { ProgramadasProvider } from 'providers/Mantenciones/ProgramadasProvider';
import { AdministradoresProvider } from 'providers/Administradores/Administradores';
import { UserActionsProvider } from 'providers/Users/UserActions';
import { ClientesActionsProvider } from 'providers/Clientes/ClientesActionsProvider';
import { PDFStyleProvider } from 'providers/PDF/PDFStyleProvider';
import { DocumentosProvider } from 'providers/Administradores/DocumentosProvider';
import { ActionsPresupuestosProvider } from 'providers/Administradores/ActionsPresupuestoProvider';
import { ActionsComprobantesProvider } from 'providers/ActionsComprobantesProvider';
import { ActionsMantencionesDepProvider } from 'providers/ActionsMantencionDepProvider';
import { ActionsODTProvider } from 'providers/ActionsOdtProvider';
import { ClientesProvider } from 'providers/Clientes/ClientesProvider';
import { SolicitudesProvider } from 'providers/SolicitudesProvider';
import { CalendarActionsProvider } from 'providers/CalendarSolc/CalendarActionsProvider';
import { UserProvider } from 'providers/Users/UserProvider';
import { ClienteProvider } from 'providers/Clientes/ClienteProvider';
import { AscensorEstadosProvider } from 'providers/AscensorEstadosProvider';
import { SolicitudesMantencionProvider } from 'providers/SolicitudesMantencionProvider';
import { OneMantencionProvider } from 'providers/OneMantencionProvider';
import { MaintanceToDoProvider } from 'providers/MaintanceToDoProvider';
import { MantencionesProvider } from 'providers/MantencionesProvider';
import { ClienteMantencionesProvider } from 'providers/ClienteMantencionesProvider';
import { ObservacionAccionesProvider } from 'providers/ObservacionAccionesProvider';
import { ObservacionesProvider } from 'providers/ObservacionesProvider';
import { ObservacionesTypesProvider } from 'providers/ObservacionesTypesProvider';

const withAdminProvider = (Component) => {
  const WrappedComponent = (props) => (
    <ClientesProviderDep>
      <ClienteAutoProvider>
        <MantencionProvider>
          <ProgramadasProvider>
            <AdministradoresProvider>
              <UserProvider>
                <UserActionsProvider>
                  <ClientesActionsProvider>
                    <PDFStyleProvider>
                      <DocumentosProvider>
                        <ActionsPresupuestosProvider>
                          <ActionsComprobantesProvider>
                            <ActionsMantencionesDepProvider>
                              <ActionsODTProvider>
                                <ClientesProvider>
                                  <ClienteProvider>
                                    <CalendarActionsProvider>
                                      <SolicitudesMantencionProvider>
                                        <SolicitudesProvider>
                                          <OneMantencionProvider>
                                            <MaintanceToDoProvider>
                                              <AscensorEstadosProvider>
                                                <MantencionesProvider>
                                                  <ClienteMantencionesProvider>
                                                    <ObservacionAccionesProvider>
                                                      <ObservacionesProvider>
                                                        <ObservacionesTypesProvider>
                                                          <Component
                                                            {...props}
                                                          />
                                                        </ObservacionesTypesProvider>
                                                      </ObservacionesProvider>
                                                    </ObservacionAccionesProvider>
                                                  </ClienteMantencionesProvider>
                                                </MantencionesProvider>
                                              </AscensorEstadosProvider>
                                            </MaintanceToDoProvider>
                                          </OneMantencionProvider>
                                        </SolicitudesProvider>
                                      </SolicitudesMantencionProvider>
                                    </CalendarActionsProvider>
                                  </ClienteProvider>
                                </ClientesProvider>
                              </ActionsODTProvider>
                            </ActionsMantencionesDepProvider>
                          </ActionsComprobantesProvider>
                        </ActionsPresupuestosProvider>
                      </DocumentosProvider>
                    </PDFStyleProvider>
                  </ClientesActionsProvider>
                </UserActionsProvider>
              </UserProvider>
            </AdministradoresProvider>
          </ProgramadasProvider>
        </MantencionProvider>
      </ClienteAutoProvider>
    </ClientesProviderDep>
  );

  // Asigna un displayName al componente envuelto
  WrappedComponent.displayName = `WithAdminProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default withAdminProvider;
