import LayoutMain from 'components/layouts/LayoutMain';
import Inicio from 'pages/liftlogic/Inicio';
import ConstructorPdf from 'pages/liftlogic/ConstructorPdf';

const liftlogicRoutes = [
  {
    path: '/',
    layout: LayoutMain,
    element: Inicio,
  },
  {
    path: '/constructorPdf',
    layout: LayoutMain,
    element: ConstructorPdf,
  },
];
export default liftlogicRoutes;
