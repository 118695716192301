import { Table } from 'antd';
import { useTenants } from 'providers/Tenant/TenantProvider';
import React from 'react';

const TenantsTable = () => {
  const { loading, data } = useTenants();
  console.log(data);
  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={[
        {
          key: 'id',
          dataIndex: 'id',
          title: 'N°',
        },
        {
          key: 'organization',
          dataIndex: 'organization',
          title: 'Organización',
          render: (value) => <span className="uppercase">{value}</span>,
        },
        {
          key: 'logo',
          dataIndex: 'logo',
          title: 'Logo',
          render: (value) => (
            <span className="uppercase">{value ? 'Sí' : 'No'}</span>
          ),
        },
        {
          key: 'createdAt',
          dataIndex: 'createdAt',
          title: 'Fecha de registro',
        },
      ]}
    />
  );
};

export default TenantsTable;
