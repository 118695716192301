import PDFOrderContainer from 'PDF/Order/PDFOrderContainer';
import BudgetDowload from 'PDF/Presupuesto/BudgetDowload/BudgetDowload';
import { App } from 'antd';
import { getAccessTokenApi } from 'api/auth';
import axios from 'axios';
import { API_PATH } from 'config';
import { createContext, useContext } from 'react';

const ActionsPresupuestosContext = createContext(null);

export const ActionsPresupuestosProvider = ({ children }) => {
  const { notification } = App.useApp();

  const getDowloadData = async (idBudget) => {
    const token = getAccessTokenApi();
    await axios
      .get(`${API_PATH}/get-budget/${idBudget}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => response.data)
      .then((value) => {
        console.log(value);
        return value;
      });
  };
  const dowload = async (idBudget) => {
    const token = getAccessTokenApi();
    const data = await axios
      .get(`${API_PATH}/get-budget/${idBudget}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => response.data)
      .then((value) => {
        return value.data;
      });
    console.log(data);
    notification.info({
      duration: 30,
      message: `Descargar Presupuesto "${data?.reason}"`,
      btn: <BudgetDowload budget={data} />,
    });
  };
  const dowloadODT = async (id) => {
    notification.open({
      duration: 30,
      message: `Descargar Orden de Trabajo N°${id}`,
      btn: <PDFOrderContainer idBudget={id} />,
    });
  };

  return (
    <ActionsPresupuestosContext.Provider
      value={{
        getDowloadData,
        dowload,
        dowloadODT,
      }}
    >
      {children}
    </ActionsPresupuestosContext.Provider>
  );
};

export const useActionsPresupuestos = () =>
  useContext(ActionsPresupuestosContext);
