import { PDFStyleProvider } from 'providers/PDF/PDFStyleProvider';
import { TenantsProvider } from 'providers/Tenant/TenantProvider';
import React from 'react';

const whitTenantProvider = (Component) => {
  const WrappedComponent = (props) => (
    <TenantsProvider>
      <PDFStyleProvider>
        <Component {...props} />
      </PDFStyleProvider>
    </TenantsProvider>
  );

  WrappedComponent.displayName = `WithTenantProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default whitTenantProvider;
