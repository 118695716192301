import React from 'react';
import { Avatar, Button, Card, Input, Typography } from 'antd';
import {
  RightOutlined,
  UserOutlined,
  SearchOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import SolicitudesTable from 'components/tables/SolicitudesTable';
import SolicitudesForDayTable from 'components/tables/SolicitudesForDayTable';
import CalendarSolc from 'components/CalendarSolc';
moment.locale('es');
const { Title } = Typography;

function Home() {
  const { user } = useAuth();
  const dayOfWeek = moment().format('dddd');

  return (
    <div className="pt-5 grid grid-cols-3 gap-4">
      {/* Barra de búsqueda */}
      <div className="col-span-3 flex">
        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="No disponible por el momento"
          className="flex-grow"
          disabled
        />
        <Button icon={<UserOutlined />} className="ml-4">
          Mi Perfil
        </Button>
      </div>

      {/* Tarjeta de saludo */}
      <Card className="col-span-3  welcome text-white">
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-between">
          <div className="text-lg ">
            <div className="uppercase">!Buen día, {user.fullname}!</div>
            <div>Ten un excelente {dayOfWeek}!</div>
          </div>
          <Avatar className="mt-3" size={64} icon={<UserOutlined />} />
        </div>
      </Card>

      {/* Tarjetas de trabajo */}
      <Card
        title="Presupuestos"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/budget'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Genera, visualiza y descarga presupuestos!
      </Card>
      <Card
        title="Archivos"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/folders'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Encontrarás todos los archivos generados!
      </Card>
      <Card
        title="Clientes"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/client'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Visualiza los clientes y sus ascensores asignados
      </Card>

      <div className="col-span-3 md:col-span-1 ">
        <div className="flex flex-col justify-between space-y-2 h-full">
          <Card
            title="Solicitudes"
            className="flex-1"
            styles={{
              body: {
                padding: 10,
              },
            }}
          >
            <SolicitudesTable />
          </Card>
          <SolicitudesForDayTable />
        </div>
      </div>

      {/* Calendario */}
      <Card
        className="col-span-3 md:col-span-2"
        title={
          <>
            <CalendarOutlined className="text-xl" /> Calendario de Mantenciones
          </>
        }
      >
        <CalendarSolc />
      </Card>

      <Card className="welcome col-span-3">
        <Title level={3} className=" text-start  ">
          <span className="uppercase text-white">
            Informes de Mantenimiento
          </span>
        </Title>
      </Card>
      <Card
        title="Actividades"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/maintance/activities'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Visualiza las actividades de mantención!
      </Card>
      <Card
        title="Programaciones automáticas"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/maintance/asignations'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Ve todos los clientes que tienen la programación automática de
        mantenciones
      </Card>
      <Card
        title="Informes"
        className="col-span-3 md:col-span-1"
        extra={
          <Link to={'/admin/maintance'}>
            <Button
              icon={<RightOutlined />}
              className=" border-none bg-primary text-white rounded-md"
            ></Button>
          </Link>
        }
      >
        Visualiza informes de mantención
      </Card>
    </div>
    // <Card className="rounded-lg shadow-md">
    //   <Title level={2} className="text-center">
    //     <AppstoreOutlined className="pr-2" /> ProApp para Administradores
    //   </Title>
    //   <Paragraph className="mt-4">
    //     ProApp ha sido diseñada específicamente pensando en las necesidades de
    //     los administradores en el ámbito de la mantención de ascensores.
    //     Optimiza tu gestión y asegura la eficiencia en cada proceso.
    //   </Paragraph>
    //   <Title level={4} className="mt-6">
    //     <KeyOutlined className="pr-2" /> Características exclusivas para
    //     administradores:
    //   </Title>
    //   <Paragraph>
    //     <ToolOutlined className="pr-2" /> Mantenimientos Centralizados:
    //     Gestionar y programar las mantenciones de ascensores nunca ha sido tan
    //     sencillo.
    //   </Paragraph>
    //   <Paragraph>
    //     <FileTextOutlined className="pr-2" /> Órdenes de Trabajo: Crea y
    //     administra órdenes de trabajo con una interfaz intuitiva y eficiente.
    //   </Paragraph>
    //   <Paragraph>
    //     <BarChartOutlined className="pr-2" /> Reportes Detallados: Accede a
    //     reportes en formato PDF con todos los detalles que necesitas para una
    //     gestión informada.
    //   </Paragraph>
    //   <Paragraph>
    //     <SyncOutlined className="pr-2" /> Automatización en la Asignación:
    //     Selecciona los clientes y ascensores, y deja que ProApp asigne y
    //     programe las mantenciones automáticamente.
    //   </Paragraph>
    //   <Title level={4} className="mt-6">
    //     <EyeOutlined className="pr-2" /> Diseño Premium:
    //   </Title>
    //   <Paragraph>
    //     Navega a través de una interfaz diseñada para proporcionar una
    //     experiencia superior. Con ProApp, la administración de ascensores se
    //     convierte en una tarea ágil y visualmente placentera.
    //   </Paragraph>
    // </Card>
  );
}

export default Home;
