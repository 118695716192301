/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import P from '../Text/P';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
  cell: {
    borderRight: '1px solid black',
    height: '100%',
  },
});
function Row(props) {
  const { item, columns } = props;

  return columns.map((column) => (
    <P key={column.key} style={{ width: column.width, ...styles.cell }}>
      {item[column.key]}
    </P>
  ));
}

function TableRow({ items, columns }) {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.id.toString()}>
      <Row key={item.id} item={item} columns={columns} />
    </View>
  ));
  return <>{rows}</>;
}

export default TableRow;
