import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row, Space, Table } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import whitDoc from 'components/HOCs/documentos/whitDoc';
import NFilter from 'components/filters/NFilter';
import ClienteSelect from 'components/selects/ClienteSelect';
import DocSelect from 'components/selects/DocSelect';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useActionsComprobantes } from 'providers/ActionsComprobantesProvider';
import { useActionsMantencionesDep } from 'providers/ActionsMantencionDepProvider';
import { useActionsODT } from 'providers/ActionsOdtProvider';
import { useActionsPresupuestos } from 'providers/Administradores/ActionsPresupuestoProvider';
import { useDocumentos } from 'providers/Administradores/DocumentosProvider';
import React, { useEffect, useState } from 'react';

const Inicio = () => {
  const [doc, setDoc] = useState('presupuestos');
  const docChange = (value) => {
    setDoc(value);
  };
  const { user } = useAuth();
  const dayOfWeek = moment().format('dddd');

  const TableComponent = whitDoc(Table);

  // Todas las acciones relacionadas con los presupuestos
  const { call, data, addFilter } = useDocumentos();
  const { dowload: dowloadPresupuesto } = useActionsPresupuestos();
  const { dowload: dowloadODT } = useActionsODT();
  const { dowload: dowloadComprobante } = useActionsComprobantes();
  const { dowload: dowloadMantencionDep } = useActionsMantencionesDep();

  useEffect(() => {
    call(call);
  }, [call]);

  // Función que filtra la data por el edificio seleccionado
  const edificioFilter = (value) => {
    // Agregamos el filtro a la data.
    // Si el filtro es true (all) entonces eliminamos los filtros
    addFilter({
      idClient: value === true ? null : value,
    });
  };

  const numFilter = (value) => {
    // Agregamos el filtro a la data.
    // Si el filtro es true (all) entonces eliminamos los filtros
    addFilter({
      id: value === true ? null : value,
    });
  };

  // console.log(data);

  return (
    <div className="">
      <div className="pt-5  space-y-2">
        <Card className="w-full  welcome text-white">
          <div className="flex flex-col justify-center items-center md:flex-row md:justify-between">
            <div className="text-lg ">
              <div className="uppercase">!Buen día, {user.fullname}!</div>
              <div>Ten un excelente {dayOfWeek}!</div>
            </div>
            <Avatar className="mt-3" size={64} icon={<UserOutlined />} />
          </div>
        </Card>

        <Card>
          <Title level={3}>Documentos e Informes</Title>
          <Paragraph>
            A continuación, podrás ver todos los documentos asociados como
            administrador de los últimos{' '}
            <span className="font-bold">3 meses</span>. Estos pueden ir desde
            los{' '}
            <span className=" font-bold">
              informes de mantención, presupuestos, ordenes de trabajo y
              comprobantes de atención
            </span>
            .
          </Paragraph>
        </Card>
        <Row gutter={5}>
          <Col span={6} xs={24} md={6} className="mb-2 md:mb-0">
            <Card className="h-full" title="Filtros">
              <Space direction="vertical" className="w-full" size="large">
                <DocSelect defaultValue={doc} onChange={docChange} />
                <ClienteSelect onChange={edificioFilter} />
                <NFilter onChange={numFilter} />
                {/* <TimeWindow /> */}
              </Space>
            </Card>
          </Col>
          <Col span={18} xs={24} md={18}>
            <Card className="h-full" title="Documentos">
              <TableComponent
                type={doc}
                data={data}
                customActions={{
                  presupuestos: (presupuesto) => (
                    <Space direction="horizontal">
                      <Button
                        type="primary"
                        onClick={() => dowloadPresupuesto(presupuesto.id)}
                        icon={<DownloadOutlined />}
                      ></Button>
                      {console.log(presupuesto)}
                      {presupuesto.status === 'odt_finalizada' ? (
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={() => dowloadODT(presupuesto.id)}
                        >
                          ODT
                        </Button>
                      ) : null}
                    </Space>
                  ),
                  comprobantes: (comprobante) => (
                    <Button
                      type="primary"
                      onClick={() => dowloadComprobante(comprobante.id)}
                      icon={<DownloadOutlined />}
                    ></Button>
                  ),
                  mantencionesDep: (mantencionDep) => (
                    <Button
                      type="primary"
                      onClick={() => dowloadMantencionDep(mantencionDep.id)}
                      icon={<DownloadOutlined />}
                    ></Button>
                  ),
                }}
              />
            </Card>
          </Col>
        </Row>
        {/* Contiene tarjeta de filtros responsive */}
        {/* Selector entre el tipo de informe, de manera predeterminada se selecciona el informe de mantención preventivo */}
        {/* Existe el filtro opcional entre edificios y todos los edificios */}
        {/* Existe el filtro de ventana de tiempo */}
        {/* Se muestra en una tabla */}
        {/* La tabla contiene en sus cabeceras el criterio de ordenamiento */}
      </div>
    </div>
  );
};

export default Inicio;
