import AgendaSolicitudes from 'components/AgendaSolicitudes';
import React from 'react';

function Home() {
  return (
    <div className="bg-white rounded shadow p-4">
      <AgendaSolicitudes />
      {/* <Title level={3}>¡Bienvenido a Prolfit!</Title>
      <Paragraph>
        Esta es una página donde los especialistas de Prolfit podrán generar
        comprobantes de atención para los clientes, crear informes de
        mantenimiento y trabajar en las órdenes de trabajo.
      </Paragraph>
      <Paragraph>
        Nuestra plataforma te brinda una experiencia intuitiva y eficiente para
        administrar y mantener registros precisos de las interacciones con los
        clientes, programar tareas de mantenimiento y dar seguimiento a las
        órdenes de trabajo pendientes.
      </Paragraph>
      <Paragraph>
        ¡Explora todas las funcionalidades y descubre cómo Prolfit puede
        optimizar tu trabajo y mejorar la satisfacción de los clientes!
      </Paragraph> */}
    </div>
  );
}

export default Home;
