import {
  CheckOutlined,
  EditFilled,
  PlusOutlined,
  SaveOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  FloatButton,
  Modal,
  Space,
  Table,
  Tabs,
} from 'antd';
import MaintanceToDoContainer from 'components/MaintanceToDoContainer';
import AscensoresMaintanceToDoForm from 'components/forms/AscensoresMaintanceToDoForm';
import FinishMaintanceForm from 'components/forms/FinishMaintanceForm';
import GeneralMaintanceToDoForm from 'components/forms/GeneralMaintanceToDoForm';
import ObservacionForm from 'components/forms/ObservacionForm';
import moment from 'moment';
import { useMaintanceToDo } from 'providers/MaintanceToDoProvider';
import { useObservaciones } from 'providers/ObservacionesProvider';
import { useOneMantencion } from 'providers/OneMantencionProvider';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const Mantencion = () => {
  const { id } = useParams();
  const location = useLocation();

  const { call, isLoading, data, hasCalled } = useOneMantencion();
  const { save, saveStatus } = useMaintanceToDo(); // Acceder al saveStatus

  useEffect(() => {
    if (!hasCalled) {
      call(id);
    }
  }, [call, hasCalled, id]);

  const { mantencion, Ascensores } = useMemo(() => {
    return data || {};
  }, [data]);

  const [finishOpen, setFinishOpen] = useState(false);
  const openFinish = () => {
    save();
    setFinishOpen(true);
  };
  const [modalProps, setModalProps] = useState(defaultModalProps);

  const { callForClient: callObservaciones, dataForClient: observaciones } =
    useObservaciones();

  useEffect(() => {
    if (mantencion?.id_cliente) {
      callObservaciones(mantencion?.id_cliente);
    }
  }, [callObservaciones, mantencion?.id_cliente]);

  const [openObservacion, setOpenObservacion] = useState(false);

  const params = new URLSearchParams(location.search);
  const onlyEdit = params.get('only_edit') === '1';

  const onFinishObservacion = () => {
    callObservaciones(mantencion?.id_cliente);
    setOpenObservacion(false);
    setModalProps(defaultModalProps);
  };

  // Define the message and color to display based on saveStatus
  const getStatusInfo = () => {
    switch (saveStatus) {
      case 'CON_CAMBIOS':
        return { message: 'Hay cambios sin guardar', color: 'orange' };
      case 'GUARDANDO':
        return { message: 'Guardando cambios...', color: 'blue' };
      case 'GUARDADO':
        return { message: 'Cambios guardados exitosamente', color: 'green' };
      case 'SIN_CAMBIOS':
      default:
        return { message: 'Sin cambios', color: 'gray' };
    }
  };

  const statusInfo = getStatusInfo();

  return (
    <div className="space-y-5 mx-2 my-2">
      <Modal
        title="Agregar observación"
        open={openObservacion}
        onCancel={() => setOpenObservacion(false)}
        footer={null}
      >
        <ObservacionForm
          onFinish={onFinishObservacion}
          initialValues={{
            id_cliente: mantencion?.id_cliente,
          }}
        />
      </Modal>
      <Card
        className="w-full"
        title={<h3>Observaciones del edificio</h3>}
        extra={
          <Button
            onClick={() => setOpenObservacion(true)}
            icon={<PlusOutlined />}
          >
            Agregar
          </Button>
        }
      >
        <Table
          dataSource={observaciones}
          rowKey={'id'}
          pagination={{
            pageSize: 5,
          }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.id - b.id,
              showSorterTooltip: false,
              width: 50,
            },
            {
              title: 'Observación',
              dataIndex: 'description',
              key: 'description',
              width: 500,
            },
            {
              title: 'Tipo',
              dataIndex: 'ObservationType',
              key: 'ObservationType',
              render: (ObservationType) => ObservationType?.name?.toUpperCase(),
            },
            {
              title: 'Fecha',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (date) => moment(date).format('YYYY-MM-DD'),
              width: 200,
            },
          ]}
        ></Table>
      </Card>
      <Card title="Items de mantención">
        <Tabs
          type="card"
          items={Ascensores?.map((ascensor) => ({
            label: `${ascensor.nombre}`,
            key: ascensor.id,
            children: (
              <MaintanceToDoContainer
                actividades={ascensor?.ActividadRespuestas}
              />
            ),
          }))}
        />
      </Card>

      <Card title="Datos generales" loading={isLoading}>
        {!isLoading ? (
          <>
            <GeneralMaintanceToDoForm
              observaciones={mantencion?.observaciones}
            />
            <Divider>Estado ascensores</Divider>
            <AscensoresMaintanceToDoForm ascensores={Ascensores} />
          </>
        ) : null}
      </Card>
      <Modal
        open={finishOpen}
        onCancel={() => setFinishOpen(false)}
        footer={null}
      >
        <FinishMaintanceForm
          idMantencion={mantencion?.id}
          handleModal={() => setFinishOpen(!finishOpen)}
        />
      </Modal>

      {!onlyEdit && (
        <FloatButton
          shape="circle"
          type="primary"
          style={{ right: 70 }}
          icon={<SendOutlined />}
          onClick={openFinish}
          className="shadow-2xl shadow-black"
        />
      )}
      <FloatButton
        type="primary"
        shape="circle"
        className="shadow-2xl shadow-black"
        onClick={() => save(id)}
        icon={<SaveOutlined />}
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />

      {/* Display the status message with color */}
      <div
        className="fixed bottom-0 left-0 w-full text-white text-center p-2 z-10"
        style={{ backgroundColor: statusInfo.color }}
      >
        {statusInfo.message}
      </div>
    </div>
  );
};

export default Mantencion;
