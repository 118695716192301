/* eslint-disable no-unused-vars */
import { View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import BudgetDataClient from './BudgetDataClient';
import BudgetRecord from './BudgetRecord';
import BudgetGuarantee from './BudgetGuarantee';
import BudgetConditions from './BudgetConditions';
import BudgetDataTransfer from './BudgetDataTransfer';
import BudgetFinish from './BudgetFinish';

const styles = StyleSheet.create({
  item: {
    marginBottom: '10px',
    padding: '0px 20px',
  },
});

function BudgetBody(props) {
  const { budget, items, images, config } = props;
  return (
    <View>
      <View style={styles.item}>
        <BudgetDataClient budget={budget} />
      </View>
      <View style={styles.item}>
        <BudgetRecord
          items={items}
          description={budget.description}
          images={images}
          discount={budget.discount}
        />
      </View>
      <View style={styles.item}>
        <BudgetGuarantee guarantee={budget.guarantee} />
      </View>
      <View style={styles.item}>
        <BudgetConditions
          conditions={budget.conditions}
          finishTime={budget.finishTime}
          include={budget.include}
        />
      </View>
      <View style={styles.item}>
        <BudgetDataTransfer config={config} />
      </View>
      <View style={styles.item}>
        <BudgetFinish config={config} />
      </View>
    </View>
  );
}
const budgetProp = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  commune: PropTypes.string.isRequired,
  email: PropTypes.string,
  reason: PropTypes.string.isRequired,
  guarantee: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  conditions: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
const itemProps = {
  id: PropTypes.number.isRequired,
  elevator: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  unitValue: PropTypes.number.isRequired,
};

const imageProps = {
  image: PropTypes.string.isRequired,
};

BudgetBody.propTypes = {
  budget: PropTypes.shape(budgetProp).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemProps).isRequired).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(imageProps)).isRequired,
};

export default BudgetBody;
