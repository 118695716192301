import { Button, Card, Modal } from 'antd';
import { arrayOf, bool, func, shape } from 'prop-types';
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import BudgetTableContainer from '../../../components/admin/Budget/BudgetTable/BudgetTableContainer';
import './Budget.scss';
import Title from 'antd/es/typography/Title';

/**
 * @param {Object} props - Props del Budget
 * @param {Array} props.budget - Arreglo con los presupuestos
 * @param {function} props.refreshBudgets - Función que actualiza los presupuestos
 */

function Budget(props) {
  const {
    budgets,
    refreshBudgets,
    handleOpenModal,
    modalOpen,
    modalAtributes,
    ModalComponent,
  } = props;

  /**
   * Opciones para modificar el modal
   */

  return (
    <div className="grid grid-cols-3 gap-4">
      <Card className="welcome col-span-3">
        <div className=" flex flex-col md:flex-row items-center md:justify-between">
          <Title level={3}>
            <span className=" text-white">Tabla de presupuestos</span>
          </Title>
          <Link to={'add-form'} className="font-large font-primary">
            <Button type="primary">Generar Presupuesto</Button>
          </Link>
        </div>
      </Card>
      <Card className="mb-2 col-span-3">
        <BudgetTableContainer
          budgets={budgets}
          refreshBudgets={refreshBudgets}
        />
      </Card>
      <Modal
        footer={modalAtributes.footer}
        open={modalOpen}
        onCancel={() => handleOpenModal(false)}
        title={modalAtributes.title}
        width={600}
      >
        <Suspense fallback={<div>Cargando...</div>}>
          {ModalComponent && <ModalComponent setReload={refreshBudgets} />}
        </Suspense>
      </Modal>
    </div>
  );
}

Budget.propTypes = {
  budgets: arrayOf(shape()).isRequired,
  refreshBudgets: func.isRequired,
  ModalComponent: shape({}),
  setModalOptions: shape({}).isRequired,
  handleOpenModal: func.isRequired,
  modalOpen: bool.isRequired,
  modalAtributes: shape({}).isRequired,
};
Budget.defaultProps = {
  ModalComponent: null,
};

export default Budget;
