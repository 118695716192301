import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Form, List, Tooltip, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import MaintanceProblemsForm from './forms/MaintanceProblemsForm';
import { useForm } from 'antd/es/form/Form';
import { API_PATH } from 'config';
const Item = ({
  Actividad,
  index,
  Respuesta,
  onChangeEstado,
  onChangeProblems,
}) => {
  const { estado } = Respuesta;
  const [state, setState] = useState(null);

  const Incidencias = useMemo(() => {
    return Respuesta.Incidencias;
  }, [Respuesta]);

  useEffect(() => {
    setState(estado);
  }, [estado]);

  // Establecer los valores iniciales del formulario solo una vez, en el montaje del componente

  const onClick = (newState) => {
    setState(newState);
    onChangeEstado(Respuesta.id, newState);
  };
  const onChangeProblem = (data) => {
    onChangeProblems(Respuesta.id, data);
  };
  const [open, setOpen] = useState(false);

  const [form] = useForm();
  const closeDrawer = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      if (values.problemas) {
        onChangeProblem(values.problemas);
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:justify-between w-full md:items-center my-4">
      <small>
        {index + 1}- {Actividad.actividad}
      </small>
      <div className="flex flex-row justify-evenly  md:flex-col md:space-y-1 my-2">
        <Tooltip key={1} title="Exitoso">
          <CheckCircleOutlined
            className={
              state === 'EXITOSO' ? ' text-green-500' : ' text-gray-400'
            }
            onClick={() => onClick('EXITOSO')}
          />
        </Tooltip>
        <Tooltip key={2} title="Con problemas">
          <WarningOutlined
            className={
              state === 'RECHAZADO' ? ' text-red-600' : ' text-gray-400'
            }
            onClick={() => onClick('RECHAZADO')}
          />
        </Tooltip>
        <Tooltip key={3} title="No aplica">
          <CloseCircleOutlined
            className={state === 'NA' ? ' text-gray-800' : ' text-gray-400'}
            onClick={() => onClick('NA')}
          />
        </Tooltip>
      </div>
      {state === 'RECHAZADO' ? (
        <Button
          onClick={() => setOpen(!open)}
          type="default"
          className=" border-primary text-primary"
        >
          Ingresar Problema
        </Button>
      ) : null}
      <Drawer
        destroyOnClose={false}
        title="Ingresar Problemas"
        open={open}
        onClose={closeDrawer}
      >
        {Incidencias ? (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              problemas: Incidencias?.map((incidencia) => ({
                ...incidencia,
                image: incidencia.image
                  ? [
                      {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: `${API_PATH}/images/maintance_problems/${incidencia.image}`,
                        existing: true,
                        response: {
                          filename: incidencia.image,
                        },
                      },
                    ]
                  : null,
              })),
            }}
          >
            <MaintanceProblemsForm
              Incidencias={Incidencias}
              initialDeleted={Incidencias?.map(
                (incidencia) => incidencia.deleted
              )}
              form={form}
            />
            {/* {loadInitialValuesAndRender()} */}
          </Form>
        ) : null}
      </Drawer>
    </div>
  );
};
const MaintanceToDoItemList = ({ item, onChangeEstado, onChangeProblems }) => {
  const { actividades } = item;
  const [showHidden, setShowHidden] = useState(false);
  const visibleActividades = actividades?.filter(
    (a) => a.Respuesta.visible || showHidden
  );

  const toggleShowHidden = () => {
    setShowHidden(!showHidden);
  };

  return (
    <List.Item>
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <p className="text-base m-0 p-0 font-semibold">{item.nombre}</p>
          <Button
            onClick={toggleShowHidden}
            type="text"
            icon={showHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          ></Button>
        </div>
        {visibleActividades.map(({ Respuesta, Actividad }, index) => (
          <div key={Respuesta.id} className="w-full">
            <Item
              onChangeEstado={onChangeEstado}
              Actividad={Actividad}
              index={index}
              Respuesta={Respuesta}
              onChangeProblems={onChangeProblems}
            />
          </div>
        ))}
      </div>
    </List.Item>
  );
};

export default MaintanceToDoItemList;
