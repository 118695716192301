import {
  AppstoreOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  FileSearchOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  OrderedListOutlined,
  SafetyOutlined,
  TeamOutlined,
  UserOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const menu = Object.freeze({
  especialista: [
    {
      label: (
        <>
          <Link to="/specialist" />
          Inicio
        </>
      ),
      icon: <HomeOutlined />,
      key: 'home',
    },
    {
      label: (
        <>
          <Link to="/specialist/AttentionVoucher" />
          Comprobantes
        </>
      ),
      icon: <FileProtectOutlined />,
      key: 'users',
    },
    {
      label: (
        <>
          <Link to="/specialist/order" />
          ODT
        </>
      ),
      icon: <OrderedListOutlined />,
      key: 'order',
    },
    {
      label: (
        <>
          <Link to="/specialist/MaintanceReports" />
          Informes de Mantención
        </>
      ),
      icon: <FilePdfOutlined />,
      key: 'MaintanceReports',
    },
  ],
  ejecutivo: [
    {
      label: (
        <>
          <Link to="/admin" />
          Home
        </>
      ),
      icon: <HomeOutlined />,
      key: 'home',
    },

    {
      label: (
        <>
          <Link to="/admin/administradores" />
          Administradores
        </>
      ),
      icon: <TeamOutlined />,
      key: 'administradores',
    },
    {
      label: (
        <>
          <Link to="/admin/mantenciones" />
          Mantenciones
        </>
      ),
      icon: <SafetyOutlined />,
      key: '/admin/mantenciones',
      children: [
        {
          key: '/',
          label: (
            <>
              <Link to="/admin/mantenciones/calendario" />
              Calendario
            </>
          ),
        },
        {
          key: '/mantenciones',
          label: (
            <>
              <Link to="/admin/mantenciones/" />
              Mantenciones
            </>
          ),
        },
        {
          key: '/actividades',
          label: (
            <>
              <Link to="/admin/mantenciones/actividades" />
              Actividades de Mantención
            </>
          ),
        },
      ],
    },
    // {
    //   label: (
    //     <>
    //       <Link to="/admin/mantenciones/actividades" />
    //       Actividades de Mantención
    //     </>
    //   ),
    //   icon: <OrderedListOutlined />,
    //   key: '/admin/mantenciones/actividades',
    // },
    {
      label: (
        <>
          <Link to="/admin/users" />
          Usuarios
        </>
      ),
      icon: <UserOutlined />,
      key: 'users',
    },
    {
      label: (
        <>
          <Link to="/admin/client" />
          Clientes
        </>
      ),
      icon: <AppstoreOutlined />,
      key: 'clients',
    },
    {
      label: (
        <>
          <Link to="/admin/budget" />
          Presupuestos
        </>
      ),
      icon: <DollarOutlined />,
      key: 'budget',
    },
    {
      label: (
        <>
          <Link to="/admin/maintanceReports" />
          Informes de mantención
        </>
      ),
      icon: <FileTextOutlined />,
      key: 'maintanceReports',
    },
    {
      label: (
        <>
          <Link to="/admin/certificacion" />
          ASIMET
        </>
      ),
      icon: <DatabaseOutlined />,
      key: 'certificacion',
    },
    {
      label: (
        <>
          <Link to="/admin/folders" />
          Archivos
        </>
      ),
      icon: <FolderOpenOutlined />,
      key: 'folders',
    },
  ],
  administrador: [
    {
      label: (
        <>
          <Link to="/" />
          Inicio
        </>
      ),
      icon: <HomeOutlined />,
      key: 'inicio',
    },
  ],
  liftlogic: [
    {
      label: (
        <>
          <Link to="/" />
          Inicio
        </>
      ),
      icon: <HomeOutlined />,
      key: 'inicio',
    },
    {
      label: (
        <>
          <Link to="/ConstructorPdf" />
          Constructor
        </>
      ),
      icon: <FilePdfOutlined />,
      key: 'Constructor',
    },
  ],
});

const MenuSider = (props) => {
  const { type } = props;
  return <Menu mode="inline" items={menu[type]} />;
};

export default MenuSider;
