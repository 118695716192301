import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const UserActionsContext = createContext(null);

// Componente proveedor del contexto
export const UserActionsProvider = ({ children }) => {
  const { call: toggleStatus, isLoading: toggleLoading } = useUAppApi({
    url: '/user/toggle-active',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  return (
    <UserActionsContext.Provider
      value={{
        toggleStatus,
        toggleLoading,
      }}
    >
      {children}
    </UserActionsContext.Provider>
  );
};

export const useUserActions = () => useContext(UserActionsContext);
