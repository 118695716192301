import { useAppApi } from 'hooks/useAppApi';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const DocumentosContext = createContext(null);

export const DocumentosProvider = ({ children }) => {
  const [filtro, setFiltro] = useState({ idCliente: null, id: null });

  const map = useCallback((result) => {
    // Transformación de los datos si es necesario
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/documentos',
    mapResults: map,
  });

  // Aquí aplicamos el filtro a los presupuestos basados en el estado `filtro`
  const presupuestosFiltrados = useMemo(() => {
    let presupuestos = data?.presupuestos;
    if (!presupuestos) return [];

    if (filtro.idClient) {
      presupuestos = presupuestos.filter((p) => p.idClient === filtro.idClient);
    }

    // Filtra por el id del presupuesto
    if (filtro.id) {
      presupuestos = presupuestos.filter((p) =>
        p.id?.toString().includes(filtro.id)
      );
    }

    return presupuestos;
  }, [data?.presupuestos, filtro]);

  // Aquí aplicamos el filtro a los comprobantes basados en el estado `filtro`
  const comprobantesFiltrados = useMemo(() => {
    let comprobantes = data?.comprobantes;
    if (!comprobantes) return [];

    // En este modelo, el id del cliente que viene de la bd es id_client y no idClient como el otro.
    if (filtro.idClient) {
      comprobantes = comprobantes.filter(
        (p) => p.id_client === filtro.idClient
      );
    }

    // Filtra por el id del comprobante
    if (filtro.id) {
      comprobantes = comprobantes.filter((p) =>
        p.id?.toString().includes(filtro.id)
      );
    }

    return comprobantes;
  }, [data?.comprobantes, filtro]);

  // // Aquí aplicamos el filtro a los comprobantes basados en el estado `filtro`
  const mantencionesDepFiltrados = useMemo(() => {
    let mantencionesDep = data?.mantencionesDeprecadas;

    if (!mantencionesDep) return [];

    // En este modelo, el id del cliente que viene de la bd es id_client y no idClient como el otro.
    if (filtro.idClient) {
      mantencionesDep = mantencionesDep.filter(
        (p) => p.id_client === filtro.idClient
      );
    }

    // Filtra por el id de la mantenciòn
    if (filtro.id) {
      mantencionesDep = mantencionesDep.filter((p) =>
        p.id?.toString().includes(filtro.id)
      );
    }

    return mantencionesDep;
  }, [data?.mantencionesDeprecadas, filtro]);

  // Función para actualizar el filtro
  const addFilter = useCallback((nuevoFiltro) => {
    setFiltro((f) => ({ ...f, ...nuevoFiltro }));
  }, []);

  return (
    <DocumentosContext.Provider
      value={{
        call,
        isLoading,
        error,
        hasCalled,
        data: {
          presupuestos: presupuestosFiltrados,
          comprobantes: comprobantesFiltrados,
          mantencionesDep: mantencionesDepFiltrados,
        },
        addFilter,
        filtro,
      }}
    >
      {children}
    </DocumentosContext.Provider>
  );
};

export const useDocumentos = () => useContext(DocumentosContext);
