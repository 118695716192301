import { Badge, Button, List, Table, Tag } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { getColorForStatus } from 'assets/data/SolicitudesEstados';
import moment from 'moment';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import React, { useEffect } from 'react';

import solicitudesMantencionStatus from 'utils/SolicitudStatus';

const AgendaSolicitudes = () => {
  const { data, call, hasCalled, isLoading, startMantSolc, isLoadingStartMan } =
    useSolicitudesMantencion();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);
  // Ordenar los datos por fecha
  const sortedData = data
    ? [...data].sort((a, b) => moment(a.date).diff(moment(b.date)))
    : [];

  const statusBadge = (item) => {
    const { color, text, mantLabel } = solicitudesMantencionStatus(item.status);
    return color;
  };

  const defLabel = (item) => {
    const { color, text, mantLabel } = solicitudesMantencionStatus(item.status);
    // console.log(item, color, text, mantLabel);
    return mantLabel;
  };

  const startMan = async (item) => {
    await startMantSolc(item);
    call();
  };
  console.log(sortedData, data);

  return (
    <div className="">
      <Table
        dataSource={sortedData}
        loading={isLoading}
        pagination={{ pageSize: 10 }}
        header={
          <div className="text-center text-lg font-semibold">
            Agenda de Mantenciones
          </div>
        }
        columns={[
          {
            title: 'Mantención',
            dataIndex: 'id_mantencion',
            key: 'id_mantencion',
            render: (id_mantencion) => 'N° ' + id_mantencion,
          },
          {
            title: 'Fecha',
            dataIndex: 'fecha_asignacion',
            key: 'fecha_asignacion',
            render: (date) => {
              console.log(date);
              return moment(date).format('MMMM, DD');
            },
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
              <Tag color={getColorForStatus(status)} className="ml-2 ">
                {status}
              </Tag>
            ),
          },
          {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
              <div className="flex flex-row justify-between">
                <Button
                  onClick={() => startMan(record)}
                  className={`mx-0 md:mx-2 `}
                  type="primary"
                  loading={isLoadingStartMan}
                >
                  {record.status?.toUpperCase()}
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AgendaSolicitudes;
