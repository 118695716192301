import { Document, View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import React from 'react';
import Moment from 'moment';
import PageA4 from '../Elements/Pages/PageA4';
import Normal from '../Elements/Text/Normal';
import Title1 from '../Elements/Text/Title1';
import Title2 from '../Elements/Text/Title2';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import 'moment/locale/es';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';
import { API_PATH } from 'config';

const styles = StyleSheet.create({
  page: {
    paddingTop: '4.5cm',
  },
  header: {
    position: 'absolute',
  },
  section: {
    margin: 10,
  },
  table: {
    display: 'table',
    width: '100%',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    padding: 5,
  },
  tableColHeaderNoObservation: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    padding: 5,
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    padding: 5,
  },
  tableColNoObservation: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  observationImage: {
    marginTop: 5,
    width: '100%',
    height: 'auto',
  },
  footer: {
    position: 'absolute',
    height: '80px',
    width: '100vw',
    bottom: 0,
  },
  normal: {
    marginBottom: 10,
  },
  incidenceSection: {
    marginTop: 10,
    paddingLeft: 10,
    borderLeft: '2px solid #000',
  },
  incidenceDescription: {
    fontSize: 10,
    marginTop: 5,
  },
});

function MantencionDocument({ mantencion: data, Ascensores, config }) {
  const { Cliente, Responsable, ...mantencion } = data;

  const renderItems = (items, includeObservation) => {
    return items.map((actividad, index) => (
      <View key={index} wrap={false}>
        <View style={styles.tableRow}>
          <View
            style={
              includeObservation
                ? styles.tableCol
                : styles.tableColNoObservation
            }
          >
            <Text style={styles.tableCell}>
              {actividad.Actividad.Categoria.nombre}
            </Text>
          </View>
          <View
            style={
              includeObservation
                ? styles.tableCol
                : styles.tableColNoObservation
            }
          >
            <Text style={styles.tableCell}>
              {actividad.Actividad.actividad}
            </Text>
          </View>
          {includeObservation && (
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {actividad.observacion || 'N/A'}
              </Text>
            </View>
          )}
        </View>
        {actividad.Incidencias && actividad.Incidencias.length > 0 && (
          <View style={styles.incidenceSection}>
            <Text>Incidencias:</Text>
            {actividad.Incidencias.map((incidencia, idx) => (
              <View key={idx}>
                <Text style={styles.incidenceDescription}>
                  Descripción: {incidencia.descripcion}
                </Text>
                {incidencia.image && (
                  <Image
                    style={styles.observationImage}
                    src={`${API_PATH}/images/maintance_problems/${incidencia.image}`}
                  />
                )}
              </View>
            ))}
          </View>
        )}
      </View>
    ));
  };
  console.log(mantencion);

  return (
    <Document>
      <PageA4 style={styles.page}>
        <View style={styles.header} fixed>
          <Header
            title={`Informe de Mantención N°${mantencion.id}`}
            config={config}
          />
        </View>
        <Title1>Estimados,</Title1>
        <Normal style={styles.normal}>
          Es un placer presentarle el informe detallado de mantenimiento
          realizado en {Cliente.address}, en la comuna de {Cliente.commune}{' '}
          realizado el{' '}
          {Moment(mantencion.createdAt).format('DD [de] MMMM [del] YYYY')}. Los
          especialistas han llevado a cabo una revisión exhaustiva de las
          instalaciones, a fin de garantizar su funcionamiento óptimo y
          seguridad.
        </Normal>
        <Normal style={styles.normal}>
          Este informe incluye un registro completo de los trabajos realizados y
          las observaciones encontradas durante la inspección. Cualquier duda o
          pregunta sobre los resultados de este mantenimiento, por favor no dude
          en contactarnos a través de nuestro correo electrónico.
        </Normal>

        <View wrap={false}>
          <Title1>Informe</Title1>
          <Normal style={styles.normal}>
            A continuación, se observa el detalle de la mantención realizada
            ordenada por ascensor y si tiene incidencias o no.
          </Normal>
        </View>
        {Ascensores?.map((ascensor) => {
          const correctItems = ascensor.ActividadRespuestas.filter(
            (item) => item.estado === 'EXITOSO'
          );
          const observationItems = ascensor.ActividadRespuestas.filter(
            (item) => item.estado === 'RECHAZADO'
          );
          const naItems = ascensor.ActividadRespuestas.filter(
            (item) => item.estado === 'NA'
          );

          return (
            <View key={ascensor.id} style={styles.section}>
              <Title2>{ascensor.nombre}</Title2>
              <Normal style={styles.normal}>
                {ascensor.nombre && `Nombre: ${ascensor.nombre}, `}
                {ascensor.marca && `Marca: ${ascensor.marca}, `}
                {ascensor.modelo && `Modelo: ${ascensor.modelo}, `}
                {ascensor.capacidad && `Capacidad: ${ascensor.capacidad}, `}
                {ascensor.pisos && `Pisos: ${ascensor.pisos}`}
              </Normal>

              {correctItems.length > 0 && (
                <>
                  <Title2>Items Resueltos Correctamente</Title2>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColHeaderNoObservation}>
                        <Text style={styles.tableCellHeader}>Categoría</Text>
                      </View>
                      <View style={styles.tableColHeaderNoObservation}>
                        <Text style={styles.tableCellHeader}>Descripción</Text>
                      </View>
                    </View>
                    {renderItems(correctItems, false)}
                  </View>
                </>
              )}

              {observationItems.length > 0 && (
                <>
                  <Title2>Items con Observaciones</Title2>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>Categoría</Text>
                      </View>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>Descripción</Text>
                      </View>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>Observación</Text>
                      </View>
                    </View>
                    {renderItems(observationItems, true)}
                  </View>
                </>
              )}

              {naItems.length > 0 && (
                <>
                  <Title2>Items No Aplican</Title2>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColHeaderNoObservation}>
                        <Text style={styles.tableCellHeader}>Categoría</Text>
                      </View>
                      <View style={styles.tableColHeaderNoObservation}>
                        <Text style={styles.tableCellHeader}>Descripción</Text>
                      </View>
                    </View>
                    {renderItems(naItems, false)}
                  </View>
                </>
              )}
            </View>
          );
        })}
        <View style={styles.footer} fixed>
          <Footer config={config} />
        </View>
        <View wrap={false}>
          <Title1>Observaciones Generales</Title1>
          <Normal>
            {mantencion.observaciones ?? 'SIN OBSERVACIONES GENERALES'}
          </Normal>
        </View>
        <View wrap={false}>
          <Title1>Información de Entrega</Title1>
          <Normal style={styles.normal}>
            A continuación, se encuentra la firma, la información del
            especialista y de quien recibe la atención. Esto, cuenta como una
            constancia firmada de que se realizó la atención.
          </Normal>
          <RecSpeSignatures
            specialistSignature={mantencion.firma_spec}
            recepcionistName={mantencion.name_recep}
            recepcionistSignature={mantencion.firma_recep}
            recepcionistCharge={mantencion.cargo_recep}
            specialistName={Responsable.fullname}
          />
        </View>
      </PageA4>
    </Document>
  );
}

export default MantencionDocument;
