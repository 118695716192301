import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, notification } from 'antd';
import React from 'react';
import BudgetDowload from '../../../../PDF/Presupuesto/BudgetDowload/BudgetDowload';
import { toggleActivatedBudgetApi } from '../../../../api/budget';
import { getAccessTokenApi } from '../../../../api/auth';
import notificationApi from '../../../../utils/notificationApi';

function BudgetDropdownButton(props) {
  const { budget, refreshData, editBudget } = props;

  const token = getAccessTokenApi();
  const toggleActivated = async (record) => {
    const result = await toggleActivatedBudgetApi(
      token,
      record.budgetActivated,
      record.idBudget
    );

    if (notificationApi(result)) {
      refreshData(true);
    }
  };

  const toggleActivatedBudget = async (record) => {
    notification.open({
      message: `¿Seguro que desea desactivar el presupuesto ${record.idBudget}?`,
      btn: (
        <Button danger type="primary" onClick={() => toggleActivated(record)}>
          Desactivar
        </Button>
      ),
    });
  };
  const items = [
    {
      label: 'Editar',
      key: 'editar',
      icon: <EditOutlined />,
    },
    {
      label: 'Eliminar',
      key: 'eliminar',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];
  const handleMenuClick = ({ key }) => {
    if (key === 'eliminar') {
      toggleActivatedBudget(budget);
    }
    if (key === 'editar') {
      editBudget(budget);
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const dowloadPdf = (record) => {
    notification.open({
      message: 'Descargar Presupuesto',
      btn: <BudgetDowload budget={record} />,
    });
  };

  return (
    <Dropdown.Button menu={menuProps} onClick={() => dowloadPdf(budget)}>
      <DownloadOutlined />
    </Dropdown.Button>
  );
}

export default BudgetDropdownButton;
