import { arrayOf, func, shape } from 'prop-types';
import React, { lazy } from 'react';
import useModal from '../../../../hooks/useModal';
import BudgetTable from './BudgetTable';

const EditBudgetFormLazy = lazy(() =>
  import('../EditBudgetForm/EditBudgetForm')
);
const AddOrderFormLazy = lazy(() => import('../AddOrdenForm'));
const OrderStatusLazy = lazy(() => import('../OrderStatus'));
const PDFOrderContainerLazy = lazy(() =>
  import('../../../../PDF/Order/PDFOrderContainer')
);
function BudgetTableContainer(props) {
  const { budgets, refreshBudgets } = props;

  const modalCases = (element) => {
    switch (element) {
      case 'EDIT_BUDGET':
        return EditBudgetFormLazy;
      case 'ADD_ODT':
        return AddOrderFormLazy;
      case 'STATUS_ODT':
        return OrderStatusLazy;
      case 'DOWLOAD_ODT':
        return PDFOrderContainerLazy;
      default:
        return null;
    }
  };

  const modal = useModal(modalCases);

  const setModalToEditForm = (budget) => {
    modal.handleAtributes({
      title: 'Editar Presupuesto',
      footer: false,
    });
    modal.handleElement('EDIT_BUDGET', {
      setModalVisible: modal.handleOpen,
      setReload: refreshBudgets,
      budget,
    });
  };

  const setModalToODTForm = (budget) => {
    modal.handleAtributes({
      title: 'Asignar Orden de trabajo',
      footer: false,
    });
    modal.handleElement('ADD_ODT', {
      budget,
      setModalVisible: modal.handleOpen,
      reload: refreshBudgets,
    });
  };

  const setModalToODTSatus = (record) => {
    modal.handleAtributes({
      title: 'Estado Orden de trabajo',
      footer: false,
    });
    modal.handleElement('STATUS_ODT', {
      budget: record,
    });
  };
  const setModalToODTDowload = (idBudget) => {
    modal.handleAtributes({
      title: 'Desacargar orden de trabajo',
      footer: false,
    });
    modal.handleElement('DOWLOAD_ODT', {
      idBudget,
    });
  };

  const ModalComponent = modal.getElement();

  const modalOptions = {
    setModalToEditForm,
    setModalToODTForm,
    setModalToODTSatus,
    setModalToODTDowload,
  };
  return (
    <BudgetTable
      budgets={budgets}
      refreshBudgets={refreshBudgets}
      modalOpen={modal.open}
      handleOpenModal={modal.handleOpen}
      ModalComponent={ModalComponent}
      modalAtributes={modal.atributes}
      modalOptions={modalOptions}
      modalProps={modal.props}
    />
  );
}

BudgetTableContainer.propTypes = {
  budgets: arrayOf(shape({})).isRequired,
  refreshBudgets: func.isRequired,
};

export default BudgetTableContainer;
