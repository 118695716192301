import { Image, View } from '@react-pdf/renderer';
import React from 'react';
import P from '../Text/P';
import { PATH_GET_FILES } from 'config';
import SpecialistBlock from './SpecialistBlock';

const RecSpeSignatures = (props) => {
  const {
    specialistSignature,
    recepcionistName,
    recepcionistSignature,
    recepcionistCharge,
    specialistName,
    specialistEspecialidad,
    specialistCargo,
    specialistOtros,
  } = props;
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '10px 0px',
      }}
    >
      <View
        style={{
          width: '50%',
        }}
      >
        <P negrita>Especialista: </P>
        {console.log(recepcionistSignature)}

        <SpecialistBlock
          specialist={{
            name: specialistName,
            especialidad: specialistEspecialidad,
            cargo: specialistCargo,
            otros: specialistOtros,
          }}
        />
        <Image source={specialistSignature} />
      </View>
      <View
        style={{
          width: '50%',
        }}
      >
        <P negrita>Recepción del cliente: </P>
        <P>
          {recepcionistName}
          {recepcionistCharge ? `, ${recepcionistCharge}` : ''}.
        </P>
        <Image source={recepcionistSignature} />
      </View>
    </View>
  );
};

export default RecSpeSignatures;
