/* eslint-disable react/prop-types */
import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableRow from './TableRow';
import TableHeader from './TableHeader';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: '1px solid black',
    borderBottom: 'initial',
    borderRight: 'initial',
    fontSize: '12pt',
    fontFamily: 'Times-Roman',
  },
});

function Table({ data, columns }) {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <TableRow items={data} columns={columns} />
      {/* <TableFooter items={data.items} /> */}
    </View>
  );
}

export default Table;
