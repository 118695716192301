import Home from '../../pages/admin/Home';
import SignIn from '../../pages/SignIn';
import Users from 'pages/admin/Users';
import Error404 from '../../pages/admin/Error404';
import Client from '../../pages/admin/Client';

// Main
import Main from '../../pages/Main';
import Folders from '../../pages/admin/Folders/Folders';
import BudgetPageContainer from '../../Containers/BudgetPageContainer/BudgetPageContainer';
import MaintanceReportsAdmin from '../../pages/admin/MaintanceReports/MaintanceReports';

/**
 * Rutas de Administrador
 */
import AddBudgetForm from 'components/admin/Budget/AddBudgetForm';
import Certificacion from 'pages/admin/Certificacion';
import AddCertification from 'Containers/AddCertification';
import CertificationDetail from 'components/applications/CertificationDetail';
import LayoutMain from 'components/layouts/LayoutMain';
import DatosCliente from 'pages/DatosCliente';
/**
 * Rutas de especialista
 */
// const HomeSpecialist = React.lazy(() => import('pages/Specialist/Home/Home'));
// const OrderDetail = React.lazy(() => import('pages/Specialist/OrderDetail'));
// const MaintanceReports = React.lazy(() =>
//   import('pages/Specialist/MaintanceReports')
// );
// const OrderContainer = React.lazy(() =>
//   import('pages/Specialist/Order/OrderContainer')
// );
// const AttentionVoucher = React.lazy(() =>
//   import('pages/Specialist/AttentionVoucher')
// );
// const FinishOrderForm = React.lazy(() =>
//   import('components/specialist/Order/FinishOrderForm')
// );
// const MReportItemsList = React.lazy(() =>
//   import('components/applications/MReportItemsList')
// );
// const FormFinishMaintanceReport = React.lazy(() =>
//   import(
//     'components/specialist/MaintanceReports/FormFinishMaintanceReport/FormFinishMaintanceReport'
//   )
// );
// const Test = React.lazy(() => import('pages/Specialist/Test'));
import HomeSpecialist from 'pages/Specialist/Home/Home';
import OrderDetail from 'pages/Specialist/OrderDetail';
import MaintanceReports from 'pages/Specialist/MaintanceReports';
import AttentionVoucher from 'pages/Specialist/AttentionVoucher';
import FinishOrderForm from 'components/specialist/Order/FinishOrderForm';
import FormFinishMaintanceReport from 'components/specialist/MaintanceReports/FormFinishMaintanceReport/FormFinishMaintanceReport';
import Test from 'pages/Specialist/Test';
import SpecialistOrders from 'pages/Specialist/SpecialistOrders';
import OneMaintanceReport from 'pages/Specialist/OneMaintanceReport';
import Maintance from 'pages/admin/Maintance/Maintance';
import Category from 'pages/admin/Maintance/Category';
import Activities from 'pages/admin/Maintance/Activities';
import Asignations from 'pages/admin/Maintance/Asignations';
import LayoutLogin from 'components/layouts/LayoutLogin';
import MaintanceToDo from 'pages/Specialist/MaintanceToDo';
import Mantenciones from 'pages/admin/Mantenciones';
import ListaMantenciones from 'pages/Specialist/lista-mantenciones';
import Administradores from 'pages/admin/Administradores';
import Mantencion from 'pages/Specialist/mantenciones/Mantencion';
import MantencionesCalendario from 'pages/admin/MantencionesCalendario';

export const routesSpecialist = [
  {
    path: '/specialist',
    layout: LayoutMain,
    element: HomeSpecialist,
  },
  {
    path: '/specialist/mantenciones/:id',
    layout: LayoutMain,
    element: Mantencion,
  },
  {
    path: '/',
    layout: LayoutMain,
    element: HomeSpecialist,
  },
  {
    path: '/specialist/AttentionVoucher',
    layout: LayoutMain,
    element: AttentionVoucher,
  },
  {
    path: '/specialist/lista-mantenciones',
    layout: LayoutMain,
    element: ListaMantenciones,
  },
  // {
  //   path: '/specialist/lista-mantenciones',
  //   layout: LayoutMain,
  //   element: MaintanceList,
  // },
  {
    path: '/specialist/lista-mantenciones/:idMantencion',
    layout: LayoutMain,
    element: MaintanceToDo,
  },
  {
    path: '/specialist/order',
    layout: LayoutMain,
    element: SpecialistOrders,
  },
  {
    path: '/specialist/order/:id',
    layout: LayoutMain,
    element: OrderDetail,
  },
  {
    path: '/specialist/order/:id/finish-form',
    layout: LayoutMain,
    element: FinishOrderForm,
  },
  {
    path: '/specialist/maintanceReports',
    layout: LayoutMain,
    element: MaintanceReports,
  },
  {
    path: '/specialist/maintanceReports/:id',
    layout: LayoutMain,
    element: OneMaintanceReport,
  },
  // {
  //   path: '/specialist/maintanceReports/:id',
  //   layout: LayoutMain,
  //   element: MReportItemsList,
  // },
  {
    path: '/specialist/maintanceReports/:id/finish-form',
    layout: LayoutMain,
    element: FormFinishMaintanceReport,
  },

  {
    path: '/*',
    layout: LayoutMain,
    element: Error404,
  },
  {
    path: '/specialist/test',
    layout: LayoutMain,
    element: Test,
  },
];
export const routesAuth = [
  {
    path: '/login',
    layout: LayoutLogin,
    element: SignIn,
  },
];
export const routesMain = [
  {
    path: '/',
    layout: LayoutMain,
    element: Main,
  },
];

export const routesAdmin = [
  {
    path: '/admin/users',
    layout: LayoutMain,
    element: Users,
  },
  {
    path: '/admin/administradores',
    layout: LayoutMain,
    element: Administradores,
  },
  {
    path: '/admin/maintance',
    layout: LayoutMain,
    element: Maintance,
  },
  {
    path: '/admin/mantenciones',
    layout: LayoutMain,
    element: Mantenciones,
  },
  {
    path: '/admin/mantenciones/calendario',
    layout: LayoutMain,
    element: MantencionesCalendario,
  },
  {
    path: '/admin/mantenciones/:id',
    layout: LayoutMain,
    element: Mantencion,
  },
  {
    path: '/admin/maintance/asignations',
    layout: LayoutMain,
    element: Asignations,
  },
  {
    path: '/admin/mantenciones/actividades',
    layout: LayoutMain,
    element: Activities,
  },
  {
    path: '/admin/maintance/categories',
    layout: LayoutMain,
    element: Category,
  },
  {
    path: '/admin/maintanceReports',
    layout: LayoutMain,
    element: MaintanceReportsAdmin,
  },
  {
    path: '/admin',
    layout: LayoutMain,
    element: Home,
  },
  {
    path: '/',
    layout: LayoutMain,
    element: Home,
  },
  {
    path: '/admin/budget',
    layout: LayoutMain,
    element: BudgetPageContainer,
  },
  {
    path: '/admin/client',
    layout: LayoutMain,
    element: Client,
  },
  {
    path: '/admin/client/:id/datos',
    layout: LayoutMain,
    element: DatosCliente,
  },
  {
    path: '/admin/folders',
    layout: LayoutMain,
    element: Folders,
  },
  {
    path: '/admin/budget/add-form',
    layout: LayoutMain,
    element: AddBudgetForm,
  },
  {
    path: '/admin/certificacion',
    layout: LayoutMain,
    element: Certificacion,
  },
  {
    path: '/admin/certificacion/nueva',
    layout: LayoutMain,
    element: AddCertification,
  },
  {
    path: '/admin/certificacion/:id',
    layout: LayoutMain,
    element: CertificationDetail,
  },
  {
    path: '/*',
    layout: LayoutMain,
    element: Error404,
  },
];
const routes = [...routesAdmin, ...routesSpecialist, ...routesMain];

export default routes;
