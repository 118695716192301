import { Badge, Calendar, List } from 'antd';
import { useCalendarActions } from 'providers/CalendarSolc/CalendarActionsProvider';
import React, { useMemo } from 'react';
import es from 'antd/es/locale/es_ES';
import { useSolicitudes } from 'providers/SolicitudesProvider';
import {
  ASIGNADO,
  ATRASADO,
  COMPLETADO,
  INACTIVO,
  SIN_ASIGNAR,
  VIGENTE,
} from 'assets/data/SolicitudesEstados';
import solicitudesMantencionStatus from 'utils/SolicitudStatus';

const CalendarCell = (date) => {
  const { data } = useSolicitudes();
  const statusBadge = (item) => {
    const { color, text, mantLabel } = solicitudesMantencionStatus(item.status);
    return color;
  };
  return (
    <ul className=" list-none m-0 p-0 overflow-hidden ">
      {data
        ?.filter((item) => item.fecha_asignacion === date.format('YYYY-MM-DD'))
        ?.map((item) => (
          <li key={item.id} className="overflow-hidden">
            <Badge color={statusBadge(item)} text={item.Cliente?.name} />
          </li>
        ))}
    </ul>
  );
};
const CalendarSolc = () => {
  const { handleSelectDay } = useCalendarActions();
  const onSelectDay = (day) => {
    handleSelectDay(day);
  };
  return (
    <Calendar cellRender={CalendarCell} onSelect={onSelectDay} locale={es} />
  );
};

export default CalendarSolc;
