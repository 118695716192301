import {
  Button,
  Card,
  Collapse,
  Form,
  Skeleton,
  message,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import MReportItemCard from 'components/applications/MReportItemCard';
import { usePutApi } from 'hooks/useApi';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const OneMaintanceReport = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();
  let [report, isLoading, callReport] = useGetToApi(
    `/maintance-report/name/category/items/${id}`,
    {
      initialValues: {
        report: {
          name_client: null,
          categorys: [],
        },
      },
    }
  );
  report = useMemo(() => {
    return report.report;
  }, [report.report]);
  const [putItems] = usePutApi({
    url: 'maintance-report/report/item',
  });
  useEffect(() => {
    callReport();
  }, [callReport]);

  const storeValues = async () => {
    const values = form.getFieldsValue();
    const valuesKeys = Object.keys(values);
    const valuesInArray = valuesKeys?.map((key) => {
      return {
        id: key,
        observation: values[key].observation ? values[key].observation : false,
        ascensores:
          values[key].ascensores && values[key].ascensores.length > 0
            ? values[key].ascensores
            : false,
      };
    });

    const response = await putItems({
      items: valuesInArray,
      id,
    });
    if (response.state === 'success') {
      notification.info({
        message: 'Ahora puede salir del informe y no se perderán los datos',
      });
    }

    // const validValues = valuesInArray?.filter(
    //   (value) => value.observation || value.ascensores
    // );
    // if (!validValues.length) {
    //   notification.warning({
    //     message: 'Debe modificar al menos un item del informe de mantención',
    //   });
    // } else {
    //   const response = await putItems({
    //     items: validValues,
    //     id,
    //   });
    //   if (response.state === 'success') {
    //     notification.info({
    //       message: 'Ahora puede salir del informe y no se perderán los datos',
    //     });
    //   }
    // }
  };

  const finishReport = async () => {
    const values = form.getFieldsValue();
    const valuesKeys = Object.keys(values);
    const valuesInArray = valuesKeys?.map((key) => {
      return {
        id: key,
        observation: values[key].observation ? values[key].observation : false,
        ascensores:
          values[key].ascensores && values[key].ascensores.length > 0
            ? values[key].ascensores
            : false,
      };
    });
    const response = await putItems({
      items: valuesInArray,
      id,
    });
    if (response.state === 'success') {
      return navigate('finish-form');
    }

    // const validValues = valuesInArray?.filter(
    //   (value) => value.observation || value.ascensores
    // );
    // if (!validValues.length) {
    //   notification.warning({
    //     message: 'Debe modificar al menos un item del informe de mantención',
    //   });
    // } else {
    //   const response = await putItems({
    //     items: validValues,
    //     id,
    //   });
    //   if (response.state === 'success') {
    //     return navigate('finish-form');
    //   }
    // }
  };
  return (
    <Skeleton loading={isLoading}>
      <Card className="flex flex-col text-center items-center mb-2">
        <Title level={3}>Informe {report.client_name}</Title>
        <div className="flex flex-row">
          <Button className="mr-2" block onClick={finishReport}>
            Finalizar Informe
          </Button>
          <Button block className="btn-success" onClick={storeValues}>
            Guardar Informe
          </Button>
        </div>
      </Card>
      <Card title="Formulario de mantención">
        <Form form={form} className="mt-5">
          <Collapse accordion>
            {report.categorys?.map(({ category, items }) => (
              <Collapse.Panel key={category} header={category}>
                <MReportItemCard items={items} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </Form>
      </Card>
    </Skeleton>
  );
};

export default OneMaintanceReport;
